import React, { createContext, useEffect, useState, useContext, useRef } from 'react';
import moment from 'moment-timezone';
import supabase from '../lib/supabase';
import { LocationContext } from './LocationContext';
import { AudioContext } from './AudioContext';

// Create contexts
const OrdersContext = createContext();
const UpdateOrdersContext = createContext();

const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// Orders provider
const OrdersProvider = ({ children }) => {
  const [orders, setOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [openOrders, setOpenOrders] = useState([]);
  const [futureOrders, setFutureOrders] = useState([]);
  const [preparedOrders, setPreparedOrders] = useState([]);
  const [totalOrderCount, setTotalOrderCount] = useState(0);
  const [totalOrderLineItems, setTotalOrderLineItems] = useState(0);
  const [totalFutureOrderCount, setTotalFutureOrderCount] = useState(0);
  const [totalFutureOrderLineItems, setTotalFutureOrderLineItems] = useState(0);

  const { activeLocation } = useContext(LocationContext);
  const { playSound } = useContext(AudioContext);
  const prevOrderCountRef = useRef();
  const ordersSubscriptionRef = useRef(null); // Store the subscription reference

  // Effect to play sound when totalOrderCount increases
  useEffect(() => {
    //console.log("totalOrderCount changed! Did it go up?", prevOrderCountRef.current, totalOrderCount);
    if (!isNaN(prevOrderCountRef?.current) && totalOrderCount > prevOrderCountRef.current) {
      //console.log("Yes, it went up! Playing a sound");
      playSound();  // Play sound only if totalOrderCount has increased
    }
    prevOrderCountRef.current = totalOrderCount;
  }, [totalOrderCount]);

  // Refs to hold the current state
  const futureOrdersRef = useRef(futureOrders);
  const openOrdersRef = useRef(openOrders);
  const preparedOrdersRef = useRef(preparedOrders);

  // Function to fetch initial data from the view
  // Adjust orders logic (sorting, time conversion, display name, etc.)
  const adjustOrders = (orders) => {
    // First, sort order line items and their modifiers
    orders.forEach(order => {
      // Sort the order line items by variation_id
      order.order_line_items.sort((a, b) => {
        if (a.variation.variation_id < b.variation.variation_id) return -1;
        if (a.variation.variation_id > b.variation.variation_id) return 1;
        return 0;
      });

      // Sort each line item's modifiers by the modifier list's ordinal
      order.order_line_items.forEach(line_item => {
        if (Array.isArray(line_item?.order_line_item_modifiers)) {
          line_item.order_line_item_modifiers.sort((a, b) => {
            let ordinalA = a.modifier?.list?.ordinal || Infinity;
            let ordinalB = b.modifier?.list?.ordinal || Infinity;
            return ordinalA - ordinalB;
          });
        }
      });
    });

    // Then adjust dates and display names
    const adjustedOrders = orders.map(order => {
      if (order.pickuptime) {
        order.pickuptime = moment.utc(order.pickuptime)
          .tz(userTimeZone)
          .format('YYYY-MM-DD HH:mm:ss');
      }
      if (order.starttime) {
        order.starttime = moment.utc(order.starttime)
          .tz(userTimeZone)
          .format('YYYY-MM-DD HH:mm:ss');
      }
      if (order.created_at) {
        order.created_at = moment.utc(order.created_at)
          .tz(userTimeZone)
          .format('YYYY-MM-DD HH:mm:ss');
      }
      if (order.prepared_at) {
        // Append 'Z' because it is UTC but not properly marked for JS
        order.prepared_at += 'Z';
      }

      order.created_display_name = false;
      if ((!order?.display_name || order?.ticket_id) && Array.isArray(order.order_line_items)) {
        let firstLineItem = order.order_line_items.find(li => li?.variation?.item?.name);
        if (!firstLineItem) {
          firstLineItem = order.order_line_items.find(li => li?.name);
        }
        if (firstLineItem) {
          order.created_display_name = true;
          if (Array.isArray(firstLineItem?.order_line_item_modifiers)) {
            firstLineItem.order_line_item_modifiers.forEach(modifier => {
              if (modifier.is_prefix === 1) {
                firstLineItem.variation.item.name =
                  modifier.name + ' ' + firstLineItem.variation.item.name;
              }
            });
          }
          if ((order?.display_name || '').trim() !== '') order.display_name += ' - '; //If there's a display name, start with that and append the line item details
          order.display_name = (order.display_name || '') + 
            firstLineItem?.variation?.item?.name || firstLineItem?.name;
          if (order.order_line_items.length > 1) {
            order.display_name += ' +';
          }
        }
      }

      return order;
    });

    return adjustedOrders;
  };

  // Fetch orders from kds_view3 and set the result via setOrders
  const fetchOrders = async () => {
    const { data, error } = await supabase
      .from('kds_view3')
      .select('*')
      .eq('location_id', activeLocation.id);

    if (error) {
      console.error('Error fetching orders:', error);
    } else {
      const adjustedOrders = adjustOrders(data);
      // Update the previous order count if needed
      if (isNaN(prevOrderCountRef?.current)) {
        prevOrderCountRef.current = adjustedOrders.filter(
          (item) =>
            item.kds_status === 'PROPOSED' &&
            new Date(item.starttime) <= new Date()
        ).length;
      }
      setOrders(adjustedOrders);
    }
  };

  // Fetch completed orders from kds_completed_view and set via setCompletedOrders
  const fetchCompletedOrders = async () => {
    const { data, error } = await supabase
    .rpc('get_kds_completed_view_by_location2', { p_location_id: activeLocation.id, p_limit: 20 }); //TODO: May need to add p_limit to KDS Settings for each location at some point

    if (error) {
      console.error('Error fetching completed orders:', error);
    } else {
      const adjustedOrders = adjustOrders(data);
      setCompletedOrders(adjustedOrders);
    }
  };


  const updateStateIfChanged = (newState, setState, ref, onChange) => {
    //console.log("Checking if we need to update",newState);
    if (JSON.stringify(ref.current) !== JSON.stringify(newState)) {
      //console.log("WE DO! Updating");
      setState(newState);
      ref.current = newState; // Keep ref updated
      if (onChange) onChange(newState); // Call onChange if provided
    }
  };
  
  const parseDateSafely = (date) => {
    const parsedDate = new Date(date);
    return isNaN(parsedDate.getTime()) ? new Date(date.replace(/-/g, '/')) : parsedDate;
  };
  
  const classifyOrders = (orders) => {
    const now = new Date();
  //console.log(orders);
  //console.log("Now",now);
    const proposedOrders = orders.filter(
      (item) => item.kds_status === 'PROPOSED' && parseDateSafely(item.starttime) <= now
    );
    const preparedOrders = orders.filter((item) => item.kds_status === 'PREPARED');
    const futureOrders = orders.filter(
      (item) => item.kds_status === 'PROPOSED' && parseDateSafely(item.starttime) > now
    );
  
    return { proposedOrders, preparedOrders, futureOrders };
  };
  
  const calculateTotals = (orders) => ({
    count: orders.length,
    lineItems: orders.reduce(
      (total, order) => total + (Array.isArray(order.order_line_items) ? order.order_line_items.length : 0),
      0
    ),
  });
  
  useEffect(() => {
    const { proposedOrders, preparedOrders, futureOrders } = classifyOrders(orders);
  
    //console.log("open (orders useEffect)");
    updateStateIfChanged(proposedOrders, setOpenOrders, openOrdersRef, (newOrders) => {
      const { count, lineItems } = calculateTotals(newOrders);
      setTotalOrderCount(count);
      setTotalOrderLineItems(lineItems);
    });
  
    //console.log("prepared (orders useEffect)");
    updateStateIfChanged(preparedOrders, setPreparedOrders, preparedOrdersRef);

    //console.log("future (orders useEffect)");
    updateStateIfChanged(futureOrders, setFutureOrders, futureOrdersRef, (newOrders) => {
      const { count, lineItems } = calculateTotals(newOrders);
      setTotalFutureOrderCount(count);
      setTotalFutureOrderLineItems(lineItems);
    });
  }, [orders]);
  
  
  useEffect(() => {
    futureOrdersRef.current = futureOrders;
    openOrdersRef.current = openOrders;
  }, [futureOrders, openOrders]);
  
  useEffect(() => {
    const updateOrderClassification = () => {
      const now = new Date();
      //console.log(orders?.[0]);
      //console.log("Now",now);
    
      const newFutureOrders = futureOrdersRef.current.filter(
        (order) => { 
          //console.log("starttime > now?",order?.starttime > now);
          return (parseDateSafely(order.starttime) > now)
        }
      );
      const newOpenOrders = [
        ...openOrdersRef.current,
        ...futureOrdersRef.current.filter((order) => new Date(order.starttime) <= now),
      ];
    
      //console.log("future");
      updateStateIfChanged(newFutureOrders, setFutureOrders, futureOrdersRef, (newOrders) => {
        const { count, lineItems } = calculateTotals(newOrders);
        setTotalFutureOrderCount(count);
        setTotalFutureOrderLineItems(lineItems);
      });
    
      //console.log("open");
      updateStateIfChanged(newOpenOrders, setOpenOrders, openOrdersRef, (newOrders) => {
        const { count, lineItems } = calculateTotals(newOrders);
        setTotalOrderCount(count);
        setTotalOrderLineItems(lineItems);
      });
    };    
  
    const intervalId = setInterval(updateOrderClassification, 5000); // Check every 5 seconds
    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);
  
  const subscribeToOrders = () => {
      //console.log("Subscribing to orders");
      
      // Check if a subscription already exists
      if (ordersSubscriptionRef.current) {
        //console.log("Order subscription already exists. Skipping...");
        return; // Prevent multiple subscriptions
      }
      
      const ordersSubscription = supabase
        .channel('orders-changes')
        .on(
          'postgres_changes',
          { event: '*', schema: 'public', table: 'orders', filter: `location_id=eq.${activeLocation.id}` },
          (payload) => {
            const updatedOrder = payload.new;
            //console.log("Updated Order",updatedOrder);
            if (payload.eventType === 'INSERT') {
              //console.log("Inserted order forcing fetchOrders");
              setTimeout(() => {
                fetchOrders();
              },1000); //Put this in a timeout to give the edge function a second longer to finish updating the line items & modifiers - sometimes this triggers too quickly and only displays the order (or nothing at all, if there are no line items)
            } else if (payload.eventType === 'UPDATE') {
              //console.log("Update in orders sub calling setOrders");
              setOrders((prevOrders) => {
                const existingOrder = prevOrders.find((order) => order.id === updatedOrder.id);
                if (existingOrder) {
                    //console.log("Updating data, not refetching");
                    return prevOrders.map((order) =>
                      order.id === updatedOrder.id
                        ? {
                            ...order,
                            ...updatedOrder,
                            display_name: updatedOrder?.display_name === null ? order.display_name : updatedOrder.display_name,
                            pickuptime: moment.utc(updatedOrder?.pickuptime).tz(userTimeZone).format('YYYY-MM-DD HH:mm:ss'),
                            starttime: moment.utc(updatedOrder?.starttime).tz(userTimeZone).format('YYYY-MM-DD HH:mm:ss')
                          }
                        : order
                    );
                } else {
                  //console.log("Not in existingOrders, we're out of sync for some reason. Refresh");
                  fetchOrders();
                  return prevOrders;
                }
              });
            }
        }
      )
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'order_line_items' },
        (payload) => {
          const updatedLineItem = payload.new;
          if (payload.eventType === 'UPDATE') {
            //console.log("Update order_line_items sub forcing setOrders",updatedLineItem);
            setOrders((prevOrders) =>
              prevOrders.map((order) => ({
                ...order,
                order_line_items: order.order_line_items.map((lineItem) =>
                  lineItem.id === updatedLineItem.id ? { ...lineItem, state: updatedLineItem.state } : lineItem
                ),
              }))
            );
          }
        }
      )
      .subscribe((status) => {
        //console.log("Subscription status",status);
        if (status === 'SUBSCRIBED') {
          //console.log('Subscribed to orders-changes channel')
          ordersSubscriptionRef.current = ordersSubscription;
        } else if (status === 'SUBSCRIPTION_ERROR' || status === 'TIMED_OUT' || status === 'CHANNEL_ERROR') {
          //console.log("cleanup in .on(status)")
          if (ordersSubscriptionRef.current) {
            supabase.removeChannel(ordersSubscriptionRef.current); // Clean up on error
            ordersSubscriptionRef.current = null; // Reset the ref
          }

          setTimeout(() => {
            fetchOrders();
            subscribeToOrders(); // Retry subscription on error - DO NOT ADD AWAIT KEYWORD HERE! IT CAN CAUSE AN INFINITE LOOP DUE TO RECURSIVELY CREATING NEW CONNECTIONS WHEN RECEIVING CHANNEL_ERRORs
          },1000); //Wait 1 second between retries to give the error time to clear
          //reject(new Error('Subscription failed or timed out'));
        } else { //Likely status === 'CLOSED'
          ordersSubscriptionRef.current = null; // Reset the ref
        }
      })
  };
  
  /*
  useEffect(() => {
    console.log("Orders updated:",orders);
  },[orders])

  useEffect(() => {
    console.log("*** Open orders updated:",openOrders);
  },[openOrders]);
  */

  useEffect(() => {
    if (!activeLocation || activeLocation?.subscription_level < 2) 
      return;

    const setupSubscriptions = async () => {
        //console.log("activeLocation changed, subscribing", activeLocation);
        try {
          fetchOrders();
          subscribeToOrders();
        } catch (error) {
            console.error("Error updating order queue:", error);
        }
    };

    setupSubscriptions();

    const handleVisibilityChange = async () => {
        if (document.visibilityState === 'visible') {
          setupSubscriptions();
        } else {
          //console.log("The page is now hidden from the user.");
          if (ordersSubscriptionRef.current) {
            supabase.removeChannel(ordersSubscriptionRef.current); // Clean up on error
            ordersSubscriptionRef.current = null; // Reset the ref
          }
        }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return async () => {
        //console.log("Cleanup on unmount or dependency change");
        if (ordersSubscriptionRef.current) {
          supabase.removeChannel(ordersSubscriptionRef.current); // Remove the relevant channel
          ordersSubscriptionRef.current = null; // Reset the ref
        }
        document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [activeLocation]);

  return (
    <OrdersContext.Provider value={{ orders, openOrders, preparedOrders, futureOrders, completedOrders, fetchCompletedOrders, totalOrderCount, totalOrderLineItems, totalFutureOrderCount, totalFutureOrderLineItems }}>
      <UpdateOrdersContext.Provider value={{ setOrders }}>
        {children}
      </UpdateOrdersContext.Provider>
    </OrdersContext.Provider>
  );
};

export { OrdersContext, OrdersProvider };