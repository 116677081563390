import React, { useContext, Fragment, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Button, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { LocationContext } from "../../../context/LocationContext";

const HeaderDots = () => {
  const { activeLocation, hasHub } = useContext(LocationContext);
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const handleNavigation = (route) => {
    navigate(route);
    document.body.click(); // Simulate clicking outside to close any open dropdowns
  };

  return (
    <Fragment>
      <div className="header-dots" style={{ height: '60px' }}>
        {activeLocation?.subscription_level >= 2 ? (
          <>
          <Button
            className="btn-icon-vertical btn-square btn-transition"
            style={{ padding: '0 10px', minWidth: '65px' }}
            outline
            color="link"
            onClick={() => handleNavigation('/kds/')}
          >
            <i className="fa-solid fa-table-columns btn-icon-wrapper btn-icon-md"
              style={{ fontSize: '1.5rem' }}></i>
            KDS
          </Button>

          {activeLocation.is_hub && (
            <Button
              className="btn-icon-vertical btn-square btn-transition"
              style={{ padding: '0 10px', minWidth: '65px' }}
              outline
              color="link"
              onClick={() => handleNavigation('/hub_kds/')}
            >
              <i className="fa-solid fa-dolly btn-icon-wrapper btn-icon-md"
                style={{ fontSize: '1.5rem' }}></i>
              Hub Orders
            </Button>
          )}
          <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle
              caret
              outline
              color="link"
              className="btn-icon-vertical btn-square btn-transition"
              style={{ padding: '0 10px', minWidth: '65px' }}
            >
              <i className="fa-solid fa-ellipsis btn-icon-wrapper btn-icon-md"
                  style={{ fontSize: '1.5rem', marginRight: '10px' }}></i>
              More
            </DropdownToggle>
            
            <DropdownMenu className="dropdown-menu-custom">
              <div className="grid-container">
              {activeLocation.subscription_level >= 2 && (
                  <div
                    className="grid-item col-gray-bg"
                  onClick={() => handleNavigation('/completed_kds/')}
                >
                  <div className="fa-stack"
                    style={{marginBottom:'20px'}}
                  >
                    <i className="fa-solid fa-table-columns menu-icon btn-icon-vertical btn-icon-md"/>
                    <i className="fa-solid fa-circle-check menu-icon" 
                      style={{ position: 'absolute', fontSize: '1rem', bottom:'-20px', right:'4px' }}
                    />
                  </div><br/>
                  Completed
                </div>
              )}
                {activeLocation.subscription_level >= 3 && (
                  <div
                    className="grid-item col-gray-bg"
                    onClick={() => handleNavigation('/checklists/')}
                  >
                    <i
                      className="fa-solid fa-square-check menu-icon btn-icon-vertical btn-icon-md"
                      style={{ fontSize: '1.5rem' }}
                    ></i><br/>
                    Checklists
                  </div>
                )}
                <div
                  className="grid-item col-gray-bg"
                  onClick={() => handleNavigation('/prep-dashboard/')}
                >
                  <i
                    className="fa-solid fa-tachograph-digital menu-icon btn-icon-vertical btn-icon-md"
                    style={{ fontSize: '1.5rem' }}
                  ></i><br/>
                  Prep
                </div>
                <div
                  className="grid-item"
                  onClick={() => handleNavigation('/recipe-box/')}
                >
                  <i
                    className="fa-solid fa-box-archive menu-icon btn-icon-vertical btn-icon-md"
                    style={{ fontSize: '1.5rem' }}
                  ></i><br/>
                  Recipes
                </div>
                <div
                  className="grid-item col-gray-bg"
                  onClick={() => handleNavigation('/waste-entry/')}
                >
                  <i
                    className="fa-solid fa-trash-can menu-icon btn-icon-vertical btn-icon-md"
                    style={{ fontSize: '1.5rem' }}
                  ></i><br/>
                  Waste
                </div>
                <div
                  className="grid-item col-gray-bg"
                  onClick={() => handleNavigation('/receive-order/')}
                >
                  <i
                    className="fa-solid fa-box-open menu-icon btn-icon-vertical btn-icon-md"
                    style={{ fontSize: '1.5rem' }}
                  ></i><br/>
                  Receive Order
                </div>
                {hasHub && (
                  <div
                    className="grid-item col-gray-bg"
                    onClick={() => handleNavigation('/create-order/')}
                  >
                    <i
                      className="fa-solid fa-cart-arrow-down menu-icon btn-icon-vertical btn-icon-md"
                      style={{ fontSize: '1.5rem' }}
                    ></i><br/>
                    Order From Hub
                  </div>
                )}
              </div>
            </DropdownMenu>
          </Dropdown>
          </>
        ) : (
          <>
            {activeLocation.is_hub && (
              <Button
                className="btn-icon-vertical btn-square btn-transition"
                style={{ padding: '0 10px', minWidth: '65px' }}
                outline
                color="link"
                onClick={() => handleNavigation('/hub_kds/')}
              >
                <i className="fa-solid fa-dolly btn-icon-wrapper btn-icon-md"
                  style={{ fontSize: '1.5rem' }}></i>
                Hub Orders
              </Button>
            )}
            {/*
            <Button
              className="btn-icon-vertical btn-square btn-transition"
              style={{ padding: '0 10px', minWidth: '65px' }}
              outline
              color="link"
              onClick={() => handleNavigation('/prep-dashboard/')}
            >
              <i className="fa-solid fa-tachograph-digital btn-icon-wrapper btn-icon-md"
                style={{ fontSize: '1.5rem' }}></i>
              Prep
            </Button>
            */}

            <Button
              className="btn-icon-vertical btn-square btn-transition"
              style={{ padding: '0 10px', minWidth: '65px' }}
              outline
              color="link"
              onClick={() => handleNavigation('/waste-entry/')}
            >
              <i className="fa-solid fa-trash-can btn-icon-wrapper btn-icon-md"
                style={{ fontSize: '1.5rem' }}></i>
              Waste
            </Button>
            {/*
            <Button
              className="btn-icon-vertical btn-square btn-transition"
              style={{ padding: '0 10px', minWidth: '65px' }}
              outline
              color="link"
              onClick={() => handleNavigation('/recipe-box/')}
            >
              <i className="fa-solid fa-box-archive btn-icon-wrapper btn-icon-md"
                style={{ fontSize: '1.5rem' }}></i>
              Recipes
            </Button>
            */}
            <Button
              className="btn-icon-vertical btn-square btn-transition"
              style={{ padding: '0 10px', minWidth: '65px' }}
              outline
              color="link"
              onClick={() => handleNavigation('/receive-order/')}
            >
              <i className="fa-solid fa-box-open btn-icon-wrapper btn-icon-md"
                style={{ fontSize: '1.5rem' }}></i>
              Receive Order
            </Button>

            
          </>
        )}
      </div>
    </Fragment>
  );
};

export default HeaderDots;
