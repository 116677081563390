import React, { Fragment, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { HubOrdersContext } from '../../../context/HubOrdersContext';
import { pluralize } from '../../../utils/pluralize';

const OrderCount = () => {
  const navigate = useNavigate();
  const { totalHubOrderCount, totalHubOrderLineItems } = useContext(HubOrdersContext);

  return (
    <Fragment>
      <div className="header-count" onClick={() => {navigate('/hub_kds')}}>
        <Row>
        <Col md="12" style={{fontSize:'.9rem'}}>
          <b>Hub Orders</b>
        </Col>
        </Row>
        <Row>
        <Col md="6">
          <div style={{fontSize: '1.3rem', lineHeight:'1.3rem'}}>{totalHubOrderCount}</div>
          <div style={{fontSize: '.6rem'}}>{pluralize(totalHubOrderCount,'Order')}</div>
        </Col>
        <Col md="6">
          <div style={{fontSize: '1.3rem', lineHeight:'1.3rem'}}>{totalHubOrderLineItems}</div>
          <div style={{fontSize: '.6rem'}}>{pluralize(totalHubOrderLineItems,'Item')}</div>
        </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default OrderCount;
