import React, { createContext, useState, useEffect, useContext } from 'react';
import supabase from '../lib/supabase';
import { TabletConfigContext } from './TabletContext';

const LocationContext = createContext();
const UpdateLocationContext = createContext();

const areObjectsEqual = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

const LocationProvider = ({ children }) => {
  const [activeLocation, setActiveLocation] = useState(() => {
    const savedLocationId = localStorage.getItem('activeLocation');
    return savedLocationId ? JSON.parse(savedLocationId) : {id:0,name:''};
  });
  const { tabletConfig } = useContext(TabletConfigContext);
  const [locations, setLocations] = useState([]);
  const [hasHub, setHasHub] = useState([]);

  useEffect(() => {
    const fetchLocations = async () => {
      const { data, error } = await supabase
        .from('locations')
        .select('*')
        .eq('archived', 0)
        .order('ordinal', { ascending: true });

      if (!error) {
        setLocations(data);
//console.log(data);
        // Check if any row has is_hub = true and update state
        setHasHub(data.some(item => item.is_hub === true));

        //console.log("Checking config for location_id",tabletConfig);
        if (!isNaN(tabletConfig?.location_id)) {
          //console.log("Setting active location from config");
          const configLocation = data.find(item => item.id === tabletConfig.location_id);
          if (configLocation) {
            setActiveLocation(configLocation);
          } else {
            //console.log("Setting default active location")
            setActiveLocation(data[0]);
          }
        } else {
          //console.log("Setting default active location")
          setActiveLocation(data[0]);
        }
      }
    };
    if (tabletConfig) {
      fetchLocations();
    }
  }, [tabletConfig]);

  useEffect(() => {
    if (activeLocation && locations.length > 0) {
      const foundItem = locations.find(item => item.id === activeLocation.id);
      if (!foundItem) {
        setActiveLocation(locations[0]);
      } else if (!areObjectsEqual(activeLocation, foundItem)) {
        setActiveLocation(foundItem);
      }
    }
  }, [locations, activeLocation?.id]);

  useEffect(() => {
    if (activeLocation !== null) {
      localStorage.setItem('activeLocation', JSON.stringify(activeLocation));
    } else {
      localStorage.removeItem('activeLocation');
    }
  }, [activeLocation]);

  const updateLocationData = async (locationId, fieldName, newValue) => {
    const { error } = await supabase
      .from('locations')
      .update({ [fieldName]: newValue })
      .eq('id', locationId);

    if (error) {
      console.error("Failed to update location in database:", error);
      return false;
    }

    setLocations(prevLocations => prevLocations.map(location => {
      if (location.id === locationId) {
        return { ...location, [fieldName]: newValue };
      }
      return location;
    }));

    if (activeLocation.id === locationId) {
      setActiveLocation(prevActiveLocation => {
        const updatedLocation = { ...prevActiveLocation, [fieldName]: newValue };
        return areObjectsEqual(prevActiveLocation, updatedLocation) ? prevActiveLocation : updatedLocation;
      });
    }

    return true;
  };

  return (
    <LocationContext.Provider value={{ activeLocation, locations, hasHub }}>
      <UpdateLocationContext.Provider value={{setActiveLocation, setLocations, updateLocationData}}>
        {children}
      </UpdateLocationContext.Provider>
    </LocationContext.Provider>
  );
};

export { LocationContext, UpdateLocationContext, LocationProvider };
