import React, { Fragment, useContext } from "react";
import { AccountContext } from "../../../context/AccountContext";
import { LocationContext } from "../../../context/LocationContext";
import {
  DropdownToggle,
  DropdownMenu,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'react-toastify/dist/ReactToastify.css';

import Clock from "../../../components/Clock";
import { useNavigate } from "react-router-dom";
import { TabletConfigContext } from "../../../context/TabletContext";

const UserBox = () => {
  const { logout } = useContext(AccountContext);
  const { activeLocation } = useContext(LocationContext);
  const { tabletConfig } = useContext(TabletConfigContext);
  const navigate = useNavigate();

  //console.log("Account in userBox",account);
  //console.log("Location:",activeLocation);

  const handleLogout = () => {
    //console.log("Logout Clicked");
    logout();

    setTimeout(() => {
      navigate('/');
    },300)
  };

  return (
    <Fragment>
      <div className="header-btn-lg pe-0" style={{ paddingLeft: '5px', marginLeft: '0' }}>
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <UncontrolledButtonDropdown>
              <DropdownToggle color="Dark" className="p-0 d-flex align-items-center" style={{minWidth: '150px'}}>
                <FontAwesomeIcon className="ms-2 opacity-8" icon={faAngleDown} style={{fontSize: '1rem'}} />
                <div className="widget-content-left header-user-info">
                  <Clock/>
                </div>
              </DropdownToggle>
              <DropdownMenu style={{width: '15rem'}}>
                <div style={{fontSize:'1.2rem', margin:'5px 20px', paddingBottom:'10px', borderBottom:'1px solid'}}>Active Location:<br/>{activeLocation?.display_name}</div>
                <div style={{margin:'10px 20px', paddingBottom:'10px', borderBottom:'1px solid'}}>Device Name:<br/>{tabletConfig?.display_name}</div>
                <div style={{margin:'10px 20px', fontSize:'1.2rem'}}>
                  <a href="#" onClick={handleLogout}>
                    Log Out
                  </a>
                </div>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserBox;
