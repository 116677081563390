import React, { createContext, useState, useEffect } from 'react';
import supabase from '../lib/supabase';

const AccountContext = createContext();
const UpdateAccountContext = createContext();

const AccountProvider = ({ children }) => {
  const [account, setAccount] = useState(null);
  const [user, setUser] = useState(null);
  const [paddleSubscriptions, setPaddleSubscriptions] = useState(null);
  const [hasSession, setHasSession] = useState(null);

  // Fetch the user session and account info from Supabase
  const fetchUser = async () => {
    //console.log("in fetchUser");
  
    try {
      //console.log("Before getSession")
      const { data, error } = await supabase.auth.getSession();
      //console.log("After getSession")
  
      if (error) {
        console.error("Error fetching session:", error);
        return;
      }
  
      const session = data?.session;
      //console.log("session", session);
  
      if (!session) {
        setUser(null);
        setAccount(null);
        setHasSession(false);
        return;
      }
  
      const user = session.user;
      setUser(user);
      setHasSession(true);
  
      const { data: userData, error: userError } = await supabase
        .from("userinfo")
        .select(
          "*, oauth_credentials(merchant_id, last_sync), paddle_subscriptions(paddle_subscription_id, paddle_product_id, paddle_customer_id, status, quantity, next_bill_date)"
        )
        .eq("user_id", user.id)
        .eq("oauth_credentials.integration_name", "square")
        .single();
  
      if (userError) {
        console.error("Error fetching user info:", userError);
        return;
      }
  
      const accountData = {
        ...userData,
        square_connected:
          userData?.oauth_credentials?.[0]?.merchant_id != null,
        has_synced: userData?.oauth_credentials?.[0]?.last_sync != null,
        pos_connected: "square",
        is_pos_connected: true,
        is_syncing: false,
      };
//console.log(accountData?.paddle_subscriptions);
      const paddle_subscriptions = accountData?.paddle_subscriptions
        ?.filter(item => item.status.toLowerCase() === 'active' || item.status.toLowerCase() === 'past_due')
        ?.reduce((acc, item) => {
          const { paddle_product_id, paddle_subscription_id, paddle_customer_id, quantity, next_bill_date, status } = item;

          // Find existing product group
          const existing = acc.find(sub => sub.paddle_product_id === paddle_product_id);
          if (existing) {
            existing.quantity += quantity || 0;
            if (new Date(next_bill_date) < existing.next_bill_date) { //We're looking for the soonest next bill date
              existing.next_bill_date = new Date(next_bill_date);
            }
            if (!existing?.paddle_customer_id && paddle_customer_id) {
              existing.paddle_customer_id = paddle_customer_id;
            }
          } else {
            acc.push({
              paddle_product_id,
              paddle_subscription_id,
              paddle_customer_id,
              status,
              next_bill_date: new Date(next_bill_date),
              quantity: quantity || 0,
            });
          }

          return acc;
      }, []);

      //console.log("paddle_subscriptions in AccountContext:", paddle_subscriptions, accountData?.paddle_subscriptions);

      paddle_subscriptions.hasPastDue = paddle_subscriptions?.some(item => item.status.toLowerCase() === 'past_due');

      setPaddleSubscriptions(paddle_subscriptions);
      setAccount(accountData);
    } catch (err) {
      console.error("Unexpected error in fetchUser:", err);
    }
  };

  useEffect(() => {
    fetchUser();
  },[]);

  const getAccessToken = async () => {
    const { data } = await supabase.auth.getSession();
    return data?.session?.access_token;
  };
  
  const login = async ({ email, password }) => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({ email, password });
  
      if (!error) {
        fetchUser(); // Call fetchUser if no error
      }
  
      return { data, error }; // Return data and error directly
    } catch (err) {
      console.error('Unexpected error during login:', err);
      throw err; // Rethrow the error to be handled by the caller
    }
  };
  
  const logoutEverywhere = async () => {
    //console.log("Logging out!");
    const { error } = await supabase.auth.signOut({ scope:'global' });
    if (error) {
      console.error('Error logging out:', error.message);
    } else {
      setAccount(null);
      setUser(null);
      setHasSession(false);
    }
  };

  const logout = async () => {
    //console.log("Logging out!");
    
    const { error } = await supabase.auth.signOut({ scope:'local' });
    //localStorage.removeItem('sb-yzxiqswbpvwvdzgwktpt-auth-token');
    if (error) {
      console.error('Error logging out:', error.message);
    } else {
      setAccount(null);
      setUser(null);
      setHasSession(false);
    }
  };  

  const menuSync = async () => {
    //console.log("MenuSync");
    if (!(await getAccessToken())) {
      console.error("Error: No user");
      return false;
    }
    if (account?.square_connected) {
      try {
        //console.log("Syncing Menu");
        setAccount((prevAccount) => ({
          ...prevAccount,
          is_syncing: true,
        }));
        const response = await fetch('https://yzxiqswbpvwvdzgwktpt.supabase.co/functions/v1/square-sync', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${await getAccessToken()}`
          }
        });
        if (!response.ok) {
          console.error(response.error);
          return false;
        }
        setAccount((prevAccount) => ({
          ...prevAccount,
          has_synced: true,
        }));
        return true;
      } catch (ex) {
        console.error("Error in order refresh from api:",ex);
      } finally {
        setAccount((prevAccount) => ({
          ...prevAccount,
          is_syncing: false,
        }));
      }
    }
    return false;
  };

  return (
    <AccountContext.Provider value={{ account, user, paddleSubscriptions, hasSession, setAccount, getAccessToken, fetchUser, login, logout, logoutEverywhere, menuSync }}>
      <UpdateAccountContext.Provider value={setAccount}>
        {children}
      </UpdateAccountContext.Provider>
    </AccountContext.Provider>
  );
};

export { AccountContext, UpdateAccountContext, AccountProvider };
