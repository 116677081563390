import React, { useEffect, Suspense, lazy, Fragment, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Loader from "react-loaders";

import { LocationProvider } from './context/LocationContext';
import { ExpoBoxProvider } from './context/ExpoBoxContext';
import { OrdersProvider } from './context/OrdersContext';
import { AudioProvider } from './context/AudioContext';
import { TabletConfigProvider } from './context/TabletContext';
import { HubOrdersProvider } from './context/HubOrdersContext';

import { AccountContext } from './context/AccountContext';

import supabase from './lib/supabase';

import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/general.css';

import { ToastContainer } from "react-toastify";

import AppHeader from "./Layout/AppHeader/";
import AppFooter from "./Layout/AppFooter/";

// Public pages
const Login = lazy(() => import('./public/login'));
const ForgotPassword = lazy(() => import('./public/forgot_password'));

// Account pages
const KDS = lazy(() => import('./pages/kds/index'));
const CompletedKDS = lazy(() => import('./pages/kds/completed'))
const HubKDS = lazy(() => import('./pages/kds/hub_kds.js'));
const RecipeBox = lazy(() => import('./pages/recipebox'));
const Waste = lazy(() => import('./pages/waste'));
const Prep = lazy(() => import('./pages/prep'));
const PickupScreen = lazy(() => import('./pages/pickup/index'));
const BoxPickupScreen = lazy(() => import('./pages/box_pickup/index'));
const Checklists = lazy(() => import('./pages/checklists'));
const CreateOrder = lazy(() => import('./pages/ordering/create_order'));
const ReceiveOrder = lazy(() => import('./pages/ordering/receive_order'));

const RedirectToLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    // Check if the user is trying to access a URL other than login/register
    if (location.pathname !== "/login" && location.pathname !== "/register" && location.pathname !== "/forgot_password") {
      localStorage.setItem('preLoginPath', location.pathname + location.search);
    }
    navigate('/login', { replace: true });
  }, [navigate, location]);

  // Optionally, return a loading indicator or null while redirecting
  return null;
};

const App = () => {
  const { hasSession } = useContext(AccountContext);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.iubenda.com/iubenda.js";
    script.async = true;
    document.body.appendChild(script);

    const autoBlockingScript = document.createElement("script");
    autoBlockingScript.src = "https://cs.iubenda.com/autoblocking/3734295.js";
    autoBlockingScript.async = true;
    document.body.appendChild(autoBlockingScript);

    const gppStubScript = document.createElement("script");
    gppStubScript.src = "//cdn.iubenda.com/cs/gpp/stub.js";
    gppStubScript.async = true;
    document.body.appendChild(gppStubScript);

    const csScript = document.createElement("script");
    csScript.src = "//cdn.iubenda.com/cs/iubenda_cs.js";
    csScript.async = true;
    document.body.appendChild(csScript);

    // Cleanup scripts on unmount
    return () => {
      document.body.removeChild(script);
      document.body.removeChild(autoBlockingScript);
      document.body.removeChild(gppStubScript);
      document.body.removeChild(csScript);
    };
  }, []);

  useEffect(() => {
    const handleMagicLinkRedirect = async () => {
      const hash = window.location.hash.substring(1);
      const params = new URLSearchParams(hash);
      const access_token = params.get('access_token');
      const refresh_token = params.get('refresh_token');

      if (access_token && refresh_token) {
        const { error } = await supabase.auth.setSession({ access_token, refresh_token });

        if (error) {
          console.error('Error setting session:', error.message);
        } else {
          window.location.href = '/dashboard'; // Redirect to the dashboard or another authenticated page
        }
      }
    };

    handleMagicLinkRedirect();
  }, []);

  return (
      <Router>
        {hasSession ? (
          <TabletConfigProvider>
            <AudioProvider>
              <LocationProvider>
                <OrdersProvider>
                  <HubOrdersProvider>
                    <Suspense
                      fallback={
                        <div className="loader-container">
                          <div className="loader-container-inner">
                            <div className="text-center">
                              <Loader type="ball-pulse-rise" />
                            </div>
                            <h6 className="mt-5">Loading...</h6>
                          </div>
                        </div>
                      }
                    >
                      <Fragment>
                        <AppHeader />
                        <div className="app-main">
                          <div className="app-main__outer">
                            <div className="app-main__inner">
                              <Routes>
                                <Route path="/" element={<Navigate to="/kds" replace />} />
                                <Route path="/recipe-box" element={<RecipeBox />} />
                                <Route path="/waste-entry" element={<Waste />} />
                                <Route path="/prep-dashboard" element={<Prep />} />
                                <Route path="/checklists" element={<Checklists />} />
                                <Route path="/kds" element={(
                                    <ExpoBoxProvider>
                                      <KDS />
                                    </ExpoBoxProvider>
                                  )}
                                />
                                <Route path="/hub_kds" element={<HubKDS />} />
                                <Route path="/completed_kds" element={<CompletedKDS />} />
                                <Route path="/pickup" element={<PickupScreen />} />
                                <Route path="/box-pickup" element={<BoxPickupScreen />} />
                                <Route path="/create-order" element={<CreateOrder />} />
                                <Route path="/receive-order" element={<ReceiveOrder />} />
                                <Route path="/*" element={<Navigate to="/kds" replace />} />
                              </Routes>
                            </div>
                          </div>
                        </div>
                        <AppFooter />
                        <ToastContainer/>
                      </Fragment>
                    </Suspense>
                  </HubOrdersProvider>
                </OrdersProvider>
              </LocationProvider>
            </AudioProvider>
          </TabletConfigProvider>
        ) : (
          <Suspense
            fallback={
              <div className="loader-container">
                <div className="loader-container-inner">
                  <div className="text-center">
                    <Loader type="ball-pulse-rise" />
                  </div>
                  <h6 className="mt-5">Loading...</h6>
                </div>
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot_password" element={<ForgotPassword />} />
              { hasSession && <Route path="/*" element={<RedirectToLogin />} /> }
            </Routes>
          </Suspense>
        )}
      </Router>
  );
};

export default App;
