import React, { Fragment } from "react";

class AppFooter extends React.Component {
  render() {
    return (
      <Fragment>
        <div id="app-footer" className="app-footer">
          <div className="app-footer__inner">
            <div className="app-footer-left">
            </div>
            <div className="app-footer-right" style={{opacity:'.7'}}>
              Copyright &copy; 2025 Orange Eight Software, LLC
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AppFooter;
