import React, { createContext, useEffect, useState, useRef, useContext } from 'react';
import supabase from '../lib/supabase';

import { AccountContext } from './AccountContext';

// Create contexts
const TabletConfigContext = createContext();

// Module-level variable to cache the promise
let uuidPromise;

const fetchUUID = (user_id) => {
  // If there's already a fingerprint being generated, return that promise.
  if (uuidPromise) {
    return uuidPromise;
  }

  // Check if a fingerprint is already stored in localStorage.
  const storedFingerprint = localStorage.getItem('tablet_uuid');
  if (storedFingerprint) {
    uuidPromise = Promise.resolve(storedFingerprint);
    return uuidPromise;
  }

  // No stored fingerprint and no promise in progress—create a new one.
  uuidPromise = new Promise((resolve, reject) => {
    // Generate a unique fingerprint using crypto.randomUUID()
    const uniqueFingerprint = crypto.randomUUID();

    // Check if this fingerprint already exists in the DB.
    supabase
      .from('tablet_config')
      .select('fingerprint')
      .eq('fingerprint', uniqueFingerprint)
      .then(({ data, error }) => {
        if (error) {
          uuidPromise = null; // Reset so future calls can retry.
          return reject(error);
        }

        // If no record is found, insert a new one.
        if (!Array.isArray(data) || data.length === 0) {
          supabase
            .from('tablet_config')
            .insert({ user_id, fingerprint: uniqueFingerprint })
            .then(({ error: config_error }) => {
              if (config_error) {
                console.error(
                  "Error creating new config entry",
                  { user_id, fingerprint: uniqueFingerprint },
                  config_error
                );
              }
              // Store the fingerprint in localStorage.
              localStorage.setItem('tablet_uuid', uniqueFingerprint);
              resolve(uniqueFingerprint);
            })
            .catch((insertError) => {
              uuidPromise = null;
              reject(insertError);
            });
        } else {
          // Record exists (extremely unlikely since the UUID is random)
          localStorage.setItem('tablet_uuid', uniqueFingerprint);
          resolve(uniqueFingerprint);
        }
      })
      .catch((dbError) => {
        uuidPromise = null;
        reject(dbError);
      });
  });

  return uuidPromise;
};

function shortIdFromUUID(uuid) {
	const shortId = uuid.split('-')[0]; // First 8 characters
	const checksum = parseInt(uuid.split('-')[1], 16) % 10; // Add a 0-9 checksum
	return `${shortId}${checksum}`;
}

// TabletConfig provider
const TabletConfigProvider = ({ children }) => {
  const [tabletConfig, setTabletConfig] = useState(null);
  const tabletSubscriptionRef = useRef(null);

  const { user } = useContext(AccountContext);

  const fetchTabletConfig = async () => {
    const fingerprint = await fetchUUID(user.id);
    if (!fingerprint) return;

    //console.log("Fingerprint",fingerprint);

    const { data, error } = await supabase
    .from('tablet_config')
    .select('*')
    .eq('fingerprint', fingerprint);

    if (error) {
      console.error('Error fetching tablet config:', error);
      setTabletConfig({});
    } else {
      if (!Array.isArray(data) || data.length === 0) {
        const { data: new_config_row, error: new_config_error } = await supabase
          .from('tablet_config')
          .insert({ user_id: user.id, fingerprint: fingerprint })
          .select('*');

        if (new_config_error) {
          console.error(
            "Error creating new tablet config entry",
            { user_id: user.id, fingerprint: fingerprint },
            new_config_error
          );
          setTabletConfig({});
        } else {
          //console.log(data);
          if (data?.[0]?.display_name === null) data[0].display_name = shortIdFromUUID(data[0].id);
          //console.log("Display Name",data?.[0].display_name);
          setTabletConfig(new_config_row?.[0]);
        }
      } else {
        //console.log(data);
        if (data?.[0]?.display_name === null) data[0].display_name = shortIdFromUUID(data[0].id);
        //console.log("Display Name",data?.[0].display_name);
        setTabletConfig(data?.[0]);
      }
    }
  };

  const subscribeToTabletConfig = async () => {
    if (tabletSubscriptionRef.current) return; // Prevent multiple subscriptions

    const fingerprint = await fetchUUID(user.id);
    if (!fingerprint) return;

    //console.log(fingerprint,user.id);
    const tabletSubscription = supabase
      .channel('tablet-changes')
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'tablet_config', filter: `fingerprint=eq.${fingerprint}` },
        (payload) => {
			//console.log("Payload",payload);
          if (payload.eventType === 'UPDATE' || payload.eventType === 'INSERT') {
			//console.log("Update or Insert");
			      if (!payload?.new?.display_name) payload.new.display_name = shortIdFromUUID(payload?.new?.id);
            //console.log("Setting Tablet Config to",payload.new)
            setTabletConfig(payload.new);
          } else if (payload.eventType === 'DELETE') {
			      //console.log("Delete");
            setTabletConfig(null);
          }
        }
      )
      .subscribe((status) => {
        //console.log("tablet_config status",status)
        if (status === 'SUBSCRIBED') {
          tabletSubscriptionRef.current = tabletSubscription;
        } else if (status === 'SUBSCRIPTION_ERROR' || status === 'CHANNEL_ERROR') {
          if (tabletSubscriptionRef.current) {
            supabase.removeChannel(tabletSubscriptionRef.current);
            tabletSubscriptionRef.current = null;
          }
          setTimeout(() => {
            subscribeToTabletConfig();
          }, 1000); // Retry subscription after a delay
        } else {
          tabletSubscriptionRef.current = null;
        }
      });
  };

  useEffect(() => {
    const setupSubscriptions = async () => {
      fetchTabletConfig();
      subscribeToTabletConfig();
    };

    setupSubscriptions();

    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible') {
        setupSubscriptions();
      } else {
        //console.log("The page is now hidden from the user.");
        if (tabletSubscriptionRef.current) {
          supabase.removeChannel(tabletSubscriptionRef.current); // Clean up on error
          tabletSubscriptionRef.current = null; // Reset the ref
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
        //console.log("Cleanup on unmount or dependency change");
        if (tabletSubscriptionRef.current) {
          supabase.removeChannel(tabletSubscriptionRef.current); // Remove the relevant channel
          tabletSubscriptionRef.current = null; // Reset the ref
        }
        document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <TabletConfigContext.Provider value={{ tabletConfig }}>
        {children}
    </TabletConfigContext.Provider>
  );
};

export { TabletConfigContext, TabletConfigProvider };
