import React, { createContext, useEffect, useState, useContext, useRef } from 'react';
import supabase from '../lib/supabase';
import { LocationContext } from './LocationContext';
import { AudioContext } from './AudioContext';

const HubOrdersContext = createContext();
const UpdateHubOrdersContext = createContext();

const HubOrdersProvider = ({ children }) => {
  // State for active hub orders only.
  const [activeHubOrders, setActiveHubOrders] = useState([]);
  const [totalHubOrderCount, setTotalHubOrderCount] = useState(0);
  const [totalHubOrderLineItems, setTotalHubOrderLineItems] = useState(0);

  const { activeLocation } = useContext(LocationContext);
  const { playSound } = useContext(AudioContext);
  const prevHubOrderCountRef = useRef();
  const hubOrdersSubscriptionRef = useRef(null);

  // Play a sound when the total number of active hub orders increases.
  useEffect(() => {
    if (!isNaN(prevHubOrderCountRef.current) && totalHubOrderCount > prevHubOrderCountRef.current) {
      playSound();
    }
    prevHubOrderCountRef.current = totalHubOrderCount;
  }, [totalHubOrderCount, playSound]);

  // ---------------------------------------------------
  // FETCH ACTIVE HUB ORDERS
  // ---------------------------------------------------
  // Fetch orders where:
  //   - filled = false, and 
  //   - hub_location_id equals activeLocation.id.
  // Also join the locations table (using a foreign key alias) to pull in the location's display name.
  const fetchHubOrders = async () => {
    try {
      const { data, error } = await supabase
        .from('hub_orders')
        .select(`
          id,
          hub_location_id,
          filled,
          created,
          locations!hub_orders_location_id_fkey (display_name),
          ingredients:hub_order_ingredient (
            id,
            ingredient_id,
            quantity,
            to_ship,
            state,
            ingredient:ingredients (name, stdUnit, components(stdUnitsPer, official_unit, cost))
          )
        `)
        .eq('filled', false)
        .eq('hub_location_id', activeLocation.id)
        .order('created', { ascending: true })
        // If you need to order by a column from a foreign table, use the appropriate option:
        .order('id', { ascending: true, referencedTable: 'ingredients' });
      if (error) {
        console.error('Error fetching hub orders:', error);
        return;
      }
      //console.log("Hub Orders", data);
      // For each hub order, add a default "received" value for each ingredient.
      const formattedHubOrders = data.map(order => ({
        ...order,
        ingredients: order.ingredients.map(item => ({
          ...item,
          received: item.quantity
        }))
      }));
      setActiveHubOrders(formattedHubOrders);

      // Calculate totals.
      const orderCount = formattedHubOrders.length;
      const lineItemsCount = formattedHubOrders.reduce(
        (total, order) => total + (Array.isArray(order.ingredients) ? order.ingredients.length : 0),
        0
      );
      setTotalHubOrderCount(orderCount);
      setTotalHubOrderLineItems(lineItemsCount);
    } catch (error) {
      console.error('Error in fetchHubOrders:', error);
    }
  };

  // ---------------------------------------------------
  // REALTIME SUBSCRIPTION FOR ACTIVE HUB ORDERS
  // ---------------------------------------------------
  const subscribeToHubOrders = () => {
	//console.log("subscribetohuborders",hubOrdersSubscriptionRef)
    if (hubOrdersSubscriptionRef.current) return; // Prevent multiple subscriptions
	//console.log("After ref.current");

    const subscription = supabase
      .channel('hub-orders-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'hub_orders',
          filter: `hub_location_id=eq.${activeLocation.id}`
        },
        (payload) => {
			//console.log("Payload",payload);
          // When an INSERT occurs, delay refetching to allow related tables to update.
          if (payload.eventType === 'INSERT') {
            setTimeout(() => {
              fetchHubOrders();
            }, 1000);
          }
          // For an UPDATE, update the local state if possible.
          else if (payload.eventType === 'UPDATE') {
            setActiveHubOrders((prevOrders) => {
              if (payload.new.filled) {
                // Remove the order from the list if it's marked as filled.
                return prevOrders.filter(order => order.id !== payload.new.id);
              }
              const exists = prevOrders.find(order => order.id === payload.new.id);
              if (exists) {
                return prevOrders.map(order =>
                  order.id === payload.new.id ? { ...order, ...payload.new } : order
                );
              } else {
                fetchHubOrders();
                return prevOrders;
              }
            });
          }          
        }
      )
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'hub_order_ingredient',
        },
        (payload) => {
          //console.log("Payload",payload);
          if (payload.eventType === 'INSERT') {
            fetchHubOrders();
          } else if (payload.eventType === 'UPDATE') {
            setActiveHubOrders((prevOrders) =>
              prevOrders.map((order) => {
                if (order.ingredients && order.ingredients.some(item => item.id === payload.new.id)) {
                const updatedIngredients = order.ingredients.map(item =>
                  item.id === payload.new.id ? { ...item, ...payload.new } : item
                );
                return { ...order, ingredients: updatedIngredients };
                }
                return order;
              })
            );
          } else if (payload.eventType === 'DELETE') {
            setActiveHubOrders((prevOrders) =>
              prevOrders.map((order) => {
                if (order.ingredients && order.ingredients.some(item => item.id === payload.old.id)) {
                const updatedIngredients = order.ingredients.filter(item => item.id !== payload.old.id);
                return { ...order, ingredients: updatedIngredients };
                }
                return order;
              })
            );
          }
        }
      )
      .subscribe((status) => {
        if (status === 'SUBSCRIBED') {
			//console.log("Subscribed!")
          hubOrdersSubscriptionRef.current = subscription;
        } else if (
          status === 'SUBSCRIPTION_ERROR' ||
          status === 'TIMED_OUT' ||
          status === 'CHANNEL_ERROR'
        ) {
          if (hubOrdersSubscriptionRef.current) {
            supabase.removeChannel(hubOrdersSubscriptionRef.current);
            hubOrdersSubscriptionRef.current = null;
          }
          setTimeout(() => {
            fetchHubOrders();
            subscribeToHubOrders();
          }, 1000);
        } else { //status = CLOSED
          hubOrdersSubscriptionRef.current = null;
        }
      });
  };

  useEffect(() => {
    if (!activeLocation) return;

    const setupSubscriptions = async () => {
      try {
        fetchHubOrders();
        subscribeToHubOrders();
      } catch (error) {
        console.error("Error updating hub orders:", error);
      }
    };
    setupSubscriptions();

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setupSubscriptions();
      } else {
        if (hubOrdersSubscriptionRef.current) {
          supabase.removeChannel(hubOrdersSubscriptionRef.current);
          hubOrdersSubscriptionRef.current = null;
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      if (hubOrdersSubscriptionRef.current) {
        supabase.removeChannel(hubOrdersSubscriptionRef.current);
        hubOrdersSubscriptionRef.current = null;
      }
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [activeLocation]);

  return (
    <HubOrdersContext.Provider
      value={{
        activeHubOrders,
        totalHubOrderCount,
        totalHubOrderLineItems
      }}
    >
      <UpdateHubOrdersContext.Provider value={{ setActiveHubOrders }}>
        {children}
      </UpdateHubOrdersContext.Provider>
    </HubOrdersContext.Provider>
  );
};

export { HubOrdersContext, HubOrdersProvider };
