  const pluralExceptions = {
	// Add any exceptions here if needed
	// Example: 'exception': 'exceptions'
	'oz': 'oz',
	'fl oz': 'fl oz',
	'fluid oz': 'fluid oz',
	'g': 'g',
	'doz eggs': 'doz eggs',
	'1 doz eggs': '1 doz eggs',
	'dozen eggs': 'dozen eggs',
	'1 dozen eggs': '1 dozen eggs',
	'burrito': 'burritos',
	'taco': 'tacos',
	'avocado': 'avocados',
	'mango': 'mangos',
	'nacho': 'nachos',
	'churro': 'churros',
	'jalapeño': 'jalapeños',
	'jalapeno': 'jalapenos',
	'pimento': 'pimentos'
  };
  
  const pluralRules = [
	[/(s|x|z|ch|sh)$/i, '$1es'], // Ends with s, x, z, ch, sh
	[/([^aeiou])y$/i, '$1ies'], // Ends with consonant + y
	[/([^aeiou]o)$/i, '$1es'], // Ends with consonant + o
	[/([^aeiou])us$/i, '$1uses'], // Ends with consonant + us
	[/sis$/i, 'ses'], // Ends with sis
	[/is$/i, 'es'], // Ends with is
	[/$/, 's'] // Default rule
  ];
  
  function pluralizeUnit(unit) {
	if (unit) {
		const lowerCasedUnit = unit.toLowerCase();
		if (pluralExceptions.hasOwnProperty(lowerCasedUnit)) {
		return pluralExceptions[lowerCasedUnit];
		}
	
		for (const [rule, replacement] of pluralRules) {
		if (rule.test(lowerCasedUnit)) {
			const pluralizedUnit = lowerCasedUnit.replace(rule, replacement);
			return capitalizeIfNecessary(unit, pluralizedUnit);
		}
		}
	
		return unit + 's'; // Default pluralization by adding 's'
	} else {
		return unit;
	}
  }
  
  function capitalizeIfNecessary(originalUnit, pluralizedUnit) {
	if (originalUnit === originalUnit.toUpperCase()) {
	  return pluralizedUnit.toUpperCase();
	} else if (originalUnit === originalUnit.toLowerCase()) {
	  return pluralizedUnit.toLowerCase();
	}
  
	const isCamelCase = originalUnit !== originalUnit.toLowerCase() && originalUnit !== originalUnit.toUpperCase();
	if (isCamelCase) {
	  const originalFirstLetter = originalUnit.charAt(0);
	  const pluralizedFirstLetter = pluralizedUnit.charAt(0);
	  const isOriginalFirstLetterUppercase = originalFirstLetter === originalFirstLetter.toUpperCase();
  
	  if (isOriginalFirstLetterUppercase) {
		return pluralizedFirstLetter.toUpperCase() + pluralizedUnit.slice(1);
	  } else {
		return pluralizedFirstLetter.toLowerCase() + pluralizedUnit.slice(1);
	  }
	}
  
	return pluralizedUnit.charAt(0).toUpperCase() + pluralizedUnit.slice(1);
  }
  
  export function pluralize(num, unit) {
	if (!unit) return unit;
	num = num * 1;
	return num !== 1 ? pluralizeUnit(unit) : unit;
  }
  